<template>
  <router-link :to="`/${$store.state.language}`" class="logo" @click="$emit('closeMenu')">
    <i class="fa-solid fa-circle-nodes"></i>
    <span>Sharedex</span>
  </router-link>
</template>

<script>
export default {
  name: 'Logo',
  emits: ['closeMenu'],
  setup() {},
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.logo {
  font-size: 2.5rem;
  color: var(--dark-10);

  i {
    margin-right: 10px;
  }

  @media #{$mobile} {
    font-size: 2rem;
  }
}
</style>
