<template>
  <div class="desktop__menu row">
    <Link
      :href="`/${$store.state.language}/documentation`"
      text="nav-15"
      style="margin-right: -10px"
    />
    <Link :href="`/${$store.state.language}/pricing`" text="nav-16" style="margin-right: 10px" />
    <Link href="https://app.sharedex.co/auth/login" text="nav-03" :external="true" :button="true" />

    <Languages @updateLanguage="$emit('updateLanguage', $event)" />
  </div>
</template>

<script>
import Link from '../components/Link'
import Languages from './components/Languages'

export default {
  name: 'Desktop',
  emits: ['updateLanguage'],
  components: { Link, Languages },
}
</script>

<style lang="scss" scoped>
.desktop__menu {
  justify-content: end;

  @media (orientation: portrait) or (max-width: 1100px) {
    display: none !important;
  }
}
</style>
